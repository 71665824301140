// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-tsx": () => import("./../../../src/pages/about.en.tsx" /* webpackChunkName: "component---src-pages-about-en-tsx" */),
  "component---src-pages-about-ru-tsx": () => import("./../../../src/pages/about.ru.tsx" /* webpackChunkName: "component---src-pages-about-ru-tsx" */),
  "component---src-pages-about-ua-tsx": () => import("./../../../src/pages/about.ua.tsx" /* webpackChunkName: "component---src-pages-about-ua-tsx" */),
  "component---src-pages-blog-en-tsx": () => import("./../../../src/pages/blog.en.tsx" /* webpackChunkName: "component---src-pages-blog-en-tsx" */),
  "component---src-pages-blog-ru-tsx": () => import("./../../../src/pages/blog.ru.tsx" /* webpackChunkName: "component---src-pages-blog-ru-tsx" */),
  "component---src-pages-blog-ua-tsx": () => import("./../../../src/pages/blog.ua.tsx" /* webpackChunkName: "component---src-pages-blog-ua-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ru-tsx": () => import("./../../../src/pages/index.ru.tsx" /* webpackChunkName: "component---src-pages-index-ru-tsx" */),
  "component---src-pages-index-ua-tsx": () => import("./../../../src/pages/index.ua.tsx" /* webpackChunkName: "component---src-pages-index-ua-tsx" */),
  "component---src-pages-team-en-tsx": () => import("./../../../src/pages/team.en.tsx" /* webpackChunkName: "component---src-pages-team-en-tsx" */),
  "component---src-pages-team-ru-tsx": () => import("./../../../src/pages/team.ru.tsx" /* webpackChunkName: "component---src-pages-team-ru-tsx" */),
  "component---src-pages-team-ua-tsx": () => import("./../../../src/pages/team.ua.tsx" /* webpackChunkName: "component---src-pages-team-ua-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

